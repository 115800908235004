<template>
  <BModal
    id="modal-class-booking-add-more-flight"
    title="Thêm chặng bay"
    title-class="text-airline font-medium-4 font-weight-bolder"
    header-class="px-1 py-50"
    body-class="px-50 pt-0"
    centered
    size="lg"
    no-close-on-backdrop
    hide-footer
    @show="onShowModal"
    @hide="onHideModal"
  >
    <!-- ANCHOR - Search -->
    <div class="d-flex-center">
      <b-button
        v-if="isShowButtonCollapse"
        variant="outline-primary"
        class="rounded-lg mt-25"
        @click="() => {
          this.$root.$emit('bv::toggle::collapse', 'collapse-search')
          isShowButtonCollapse = !isShowButtonCollapse
        }"
      >
        <feather-icon icon="SearchIcon" />
        Tìm kiếm
      </b-button>
    </div>

    <b-collapse
      v-if="searchData && !isEmpty(searchData.flights)"
      id="collapse-search"
      class="mt-1"
      visible
    >
      <div class="search_class mb-1 border-info rounded pt-25">
        <IAmOverlay
          :loading="loading"
          :spinnerVariant="'info'"
        >
          <b-row
            class="section_airport m-0 px-25 d-flex-between"
            no-gutters
          >
            <b-col
              cols="12"
              lg="5"
              class="px-25 mb-50 mb-lg-0"
            >
              <b>Điểm đi</b>
              <ResultSearchAirportSelect
                :data-prop.sync="searchData.flights[0].startPoint"
                class="border rounded"
                :appendToBody="false"
              />
            </b-col>
            <div class="switch_airport mx-lg-50 d-flex-center">
              <b-button
                variant="primary"
                class="btn-icon rounded-circle p-1 border"
                @click="swapHandle"
              >
                <IAmIcon
                  icon="swap"
                  class="d-flex-center text-white"
                  size="20px"
                />
              </b-button>
            </div>
            <b-col
              cols="12"
              lg="5"
              class="px-25 mb-50 mb-lg-0"
            >
              <b>Điểm đến</b>
              <ResultSearchAirportSelect
                :data-prop.sync="searchData.flights[0].endPoint"
                class="border rounded"
                :appendToBody="false"
                :right="true"
              />
            </b-col>
          </b-row>

          <b-row
            class="mx-0 mt-50 px-25"
            no-gutters
          >
            <!-- ANCHOR Ngày đi -->
            <b-col
              cols="12"
              lg="4"
              class="mb-75 mb-lg-0 px-50"
            >
              <b>Ngày đi</b>
              <SearchDatePicker
                :date.sync="searchData.flights[0].departDate"
                :config="{ minDate: 'today' }"
                :class="`border rounded mr-25`"
                :style="`${['lg', 'xl'].includes(appBreakPoint) ? 'height: 52px;' : ''}`"
                :flight="[searchData.flights[0].startPoint, searchData.flights[0].endPoint]"
              />
            </b-col>

            <!-- ANCHOR Direct flight select -->
            <b-col
              cols="12"
              lg="4"
              class="mb-75 mb-lg-0 px-50 d-flex-center"
            >
              <b-form-checkbox
                id="isDirectFlightOnly"
                v-model="isDirectFlightOnly"
                switch
                class="custom-control-warning"
                @input="val => handleUpdateNumberOfStop(val)"
              >
                <span class="cursor-pointer text-body fw-600">{{ $t('flight.directFlightSelect') }}</span>
              </b-form-checkbox>
            </b-col>

            <!-- ANCHOR permittedCarriers select -->
            <b-col
              cols="12"
              lg="4"
              class="mb-75 mb-lg-0 px-50 d-flex-center"
            >
              <SearchCarriersSelect
                :carriers.sync="searchData.permittedCarriers"
                class="flex-fill border rounded w-100"
              />
            </b-col>
          </b-row>

          <div class="d-flex-center mb-50">
            <b-button
              variant="outline-secondary"
              class="py-75 px-1 mr-2 fw-600"
              pill
              @click="() => {
                isShowButtonCollapse = !isShowButtonCollapse
                this.$root.$emit('bv::toggle::collapse', 'collapse-search')
              }"
            >
              <feather-icon icon="ArrowUpIcon" />
              Thu nhỏ
            </b-button>
            <b-button
              v-ripple.200="'rgba(113, 102, 240, 0.15)'"
              class="btn-gradient py-50 px-1 border-0"
              pill
              @click="searchFlightHandle"
            >
              <div class="d-flex-center fw-600">
                <img
                  src="@icons/search.svg"
                  alt="Search"
                  class="mr-25 ico-bold"
                >
                {{ $t('reservation.searchFlight') }}
              </div>
            </b-button>
          </div>
        </IAmOverlay>
      </div>
    </b-collapse>

    <!-- ANCHOR - Result Trip -->
    <div
      v-if="responseSearchClassBooking"
      class="my-50"
    >
      <!-- v-for="trip of responseSearchClassBooking.filter(trip => trip.segments.length === 1)" -->
      <div
        v-for="(trip, tripIndex) of responseSearchClassBooking"
        :key="trip.tripUuid"
        class="border-secondary-2 rounded my-1 p-50"
      >
        <div
          v-for="(segment, segIndex) of trip.segments"
          :key="segment.segmentUuid"
        >
          <div>
            <b-badge
              v-if="segIndex === 0"
              variant="info"
              class="mr-25 fw-700"
              pill
            >
              {{ tripIndex + 1 }}
            </b-badge>
            <span class="fw-700">{{ convertISODateTime(segment.departureDate, segment.departureTimezone).time }}-{{ convertISODateTime(segment.arrivalDate, segment.arrivalTimezone).time }} {{ convertISODateTime(segment.departureDate, segment.departureTimezone).dayAndMonth }} {{ segment.airline }}{{ segment.flightNumber }} {{ segment.departure }}{{ segment.arrival }}</span>
          </div>

          <template v-if="!isEmpty(segment.bookingClassAvail)">
            <b-button
              v-for="bookingClass of segment.bookingClassAvail"
              :key="bookingClass.bookingClassUuid"
              :variant="selectedBookingClassUuid.some(it => it.bookingClassUuid === bookingClass.bookingClassUuid) ? 'primary' : 'outline-primary'"
              class="rounded m-25 p-50 fw-700 font-medium-2"
              @click="selectTripHandle(trip, segment.segmentUuid, bookingClass.bookingClassUuid)"
            >
              {{ bookingClass.code }}({{ bookingClass.availability }})
            </b-button>
          </template>
          <template v-else>
            <b-badge
              variant="danger"
              class="pt-50 fw-700"
            >
              Không có chỗ
            </b-badge>
          </template>
        </div>
      </div>
    </div>

    <!-- ANCHOR - Warning search -->
    <div
      v-else-if="!responseSearchClassBooking && isShowButtonCollapse"
      class="py-2"
    >
      <b-alert
        show
        variant="warning"
        class="py-75 px-1 mx-1 fw-700 d-flex-center"
      >
        <b> Vui lòng tìm kiếm chuyến bay!</b>
      </b-alert>
    </div>

    <!-- ANCHOR - Button -->
    <div :class="`${responseSearchClassBooking ? 'footer_modal_class' : ''} d-flex justify-content-end py-1`">
      <BButton
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="danger"
        class="center rounded-pill px-2 mr-1 fw-600"
        @click="handleCloseModal"
      >
        {{ $t('reservation.back') }}
      </BButton>
      <BButton
        variant="warning"
        class="center rounded-pill px-2 mr-1 fw-600"
        @click="submitAddFlightHandle"
      >
        {{ $t('reservation.addFlight') }}
      </BButton>
    </div>
  </BModal>
</template>

<script>
import {
  BModal,
  BButton,
  BCol,
  BRow,
  BAlert,
  BBadge,
  BFormCheckbox,
  BCollapse,
} from 'bootstrap-vue'
import {
  computed,
  ref,
  toRefs,
} from '@vue/composition-api'
import { v4 as uuidv4 } from 'uuid'

import { convertISODateTime, convertShortTrip } from '@/@core/utils/filter'
import { apiBooking } from '@/api'

import useClassBookingHandle from '@flightv2/result-class-booking/useClassBookingHandle'
import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BAlert,
    BModal,
    BButton,
    BCol,
    BRow,
    BBadge,
    BFormCheckbox,
    BCollapse,

    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    SearchDatePicker: () => import('@flightv2/search/components/SearchDatePicker.vue'),
    ResultSearchAirportSelect: () => import('@flightv2/search/components/SearchAirportSelect_result.vue'),
    SearchCarriersSelect: () => import('@flightv2/search/components/SearchCarriersSelect.vue'),
  },
  props: {
    dataFlight: {
      type: [Object, null],
      default: () => null,
    },
  },
  setup(props, { emit, root }) {
    const {
      store,
      airlineSearch,
      cloneDeep,
      isEmpty,
      delay,
      toastSuccess,
      toastWarning,
      dataAddMoreFlight,
    } = useClassBookingHandle()
    const {
      FLIGHT_APP_STORE_MODULE_NAME,
      fetchAirportGroup,
    } = useBookingHandle()

    const defaultSearchData = {
      adult: 1,
      child: 0,
      infant: 0,
      type: 'OW',
      currency: 'VND',
      numberOfStop: 99,
      flights: [
        {
          startPoint: null,
          endPoint: null,
          departDate: convertISODateTime(new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1))).dateFilter,
        },
      ],
      promoCodes: [],
      source: 'VN1A',
      isBook: true,
      permittedCarriers: [],
    }

    const { dataFlight } = toRefs(props)
    const loading = ref(false)
    const responseSearchClassBooking = ref() // need reset
    const tripSelected = ref(null) // need reset
    const searchData = ref(null) // need reset
    const selectedBookingClassUuid = ref([]) // need reset
    const isDirectFlightOnly = ref(false) // need reset

    const isShowButtonCollapse = ref(false) // need reset

    const isSelectedAllSegments = computed(() => {
      if (isEmpty(tripSelected.value) || isEmpty(selectedBookingClassUuid.value)) return false

      if (tripSelected.value.segments.length !== selectedBookingClassUuid.value.length) return false

      return true
    })

    function handleUpdateNumberOfStop(val) {
      if (val === true) {
        searchData.value.numberOfStop = 0
      } else {
        searchData.value.numberOfStop = 99
      }
    }

    function onShowModal() {
      searchData.value = cloneDeep(defaultSearchData)
      const storageData = localStorage.getItem('searchFlight') ? JSON.parse(localStorage.getItem('searchFlight')) : null
      searchData.value.flights[0].startPoint = dataFlight.value?.departure
      searchData.value.flights[0].endPoint = dataFlight.value?.arrival
      searchData.value.flights[0].departDate = dataFlight.value?.date || storageData?.flights?.[0]?.departDate
      if (isEmpty(store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getAirportGroup`])) { fetchAirportGroup() }
    }

    function onHideModal() { // reset data
      responseSearchClassBooking.value = undefined
      searchData.value = null
      tripSelected.value = null
      selectedBookingClassUuid.value = []
      isDirectFlightOnly.value = false
      isShowButtonCollapse.value = false
      emit('update:dataFlight', null)
    }

    function handleCloseModal() {
      root.$bvModal.hide('modal-class-booking-add-more-flight')
    }

    async function searchFlightHandle() {
      const payload = cloneDeep(searchData.value)
      if (isEmpty(payload.permittedCarriers)) {
        delete payload.permittedCarriers
      }

      try {
        loading.value = true
        responseSearchClassBooking.value = undefined
        const res = await apiBooking.searchClassBooking(payload)

        if (!isEmpty(res)) {
          const dataWithUuid = res[0].trips.map(trip => ({
            ...trip,
            tripUuid: uuidv4(),
            segments: trip.segments.map(segment => ({
              ...segment,
              segmentUuid: uuidv4(),
              bookingClassAvail: !isEmpty(segment.bookingClassAvail)
                ? segment.bookingClassAvail.map(cls => ({
                  ...cls,
                  bookingClassUuid: uuidv4(),
                }))
                : [],
            })),
          }))

          responseSearchClassBooking.value = dataWithUuid
          this.$root.$emit('bv::toggle::collapse', 'collapse-search')
          isShowButtonCollapse.value = !isShowButtonCollapse.value
        }
      } catch (error) {
        console.error('Error search-class-booking flight: ', error)
      } finally {
        loading.value = false
      }
    }

    async function submitAddFlightHandle() {
      if (!isSelectedAllSegments.value) {
        toastWarning({
          title: 'Vui lòng chọn hành trình',
        })
        return
      }

      const newTrip = {
        ...tripSelected.value,
        segments: tripSelected.value.segments.map(s => ({
          ...s,
          bookingClass: s.bookingClassAvail.find(bk => selectedBookingClassUuid.value.some(it => it.bookingClassUuid === bk.bookingClassUuid)),
          isExtraTrip: true,
        })),
      }

      const rawData = cloneDeep(dataAddMoreFlight.value)
      store.dispatch('app-flight-v2/setAddMoreFlightClassBooking', [...rawData, newTrip])
      toastSuccess({
        title: 'Thêm chặng thành công.',
      })
      await delay(200)
      handleCloseModal()
    }

    function selectTripHandle(tripData, segmentUuid, bookingClassUuid) {
      const newData = { segmentUuid, bookingClassUuid }

      if (tripSelected.value?.tripUuid !== tripData.tripUuid) {
        tripSelected.value = tripData
        selectedBookingClassUuid.value = [newData]
        return
      }

      const equalSegmentIndex = selectedBookingClassUuid.value.findIndex(it => it.segmentUuid === segmentUuid)
      const rawData = cloneDeep(selectedBookingClassUuid.value)

      if (equalSegmentIndex !== -1) {
        rawData.splice(equalSegmentIndex, 1, newData)
      } else {
        rawData.push(newData)
      }

      selectedBookingClassUuid.value = rawData
    }

    function swapHandle() {
      const temp = searchData.value.flights[0].startPoint
      searchData.value.flights[0].startPoint = searchData.value.flights[0].endPoint
      searchData.value.flights[0].endPoint = temp
    }

    function handleHideSearch() {
      isShowButtonCollapse.value = !isShowButtonCollapse.value
      this.$root.$emit('bv::toggle::collapse', 'my-collapse-id')
    }

    return {
      loading,
      isEmpty,
      searchData,
      convertISODateTime,
      convertShortTrip,
      searchFlightHandle,
      handleUpdateNumberOfStop,
      selectedBookingClassUuid,
      responseSearchClassBooking,
      submitAddFlightHandle,
      onShowModal,
      onHideModal,
      handleCloseModal,
      selectTripHandle,
      tripSelected,
      swapHandle,
      isDirectFlightOnly,
      isShowButtonCollapse,
      handleHideSearch,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.search_class {
  background-color: white;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.3);
  position: sticky;
  top: 5px;
}

.footer_modal_class {
  position: sticky;
  bottom: 0;
  background: white;
}

.section_airport {
  position: relative;

  .switch_airport {
    @media (max-width: 991px) {
      position: absolute;
      z-index: 99999;
      right: -45px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

</style>
